import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { navigate } from 'gatsby-link';
import Header from '../components/header/header';
import SEO from '../components/SEO';
import { useDimension } from '../components/getDimension';

const Games = (props) => {
  const { gameUrl, title } = props.pageContext;
  const dim = useDimension();

  return (
    <div className="main-container" style={{ width: '100vw', height: '100vh' }}>
      <SEO title={title} />
      <Header show={true} />
      <div className="back-game" onClick={() => navigate(-1)}>
        <div>
          <FaArrowLeft color="#fc650a" /> <span>Back</span>
        </div>
      </div>
      <iframe src={`${gameUrl}#app`} height={dim.height} width={dim.width} marginHeight="0" />
    </div>
  );
};

export default Games;
