import React, { useState, useEffect } from 'react';

const useDimension = () => {
  const [dim, setDim] = useState({ width: 0, height: 0 });

  useEffect(() => {
    setDim({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener('resize', (dimensions) => {
      setDim({ width: window.innerWidth, height: window.innerHeight });
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  return dim;
};

export { useDimension };
